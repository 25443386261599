import _TextEncoder from "./lib/TextEncoder";
import _TextDecoder from "./lib/TextDecoder";
import _encodingIndexes from "./encoding-indexes";
import _lib from "./lib";
var exports = {};
const TextEncoder = _TextEncoder;
const TextDecoder = _TextDecoder;
const EncodingIndexes = _encodingIndexes;
const {
  getEncoding
} = _lib; //
// Implementation of Encoding specification
// https://encoding.spec.whatwg.org/
//

exports.TextEncoder = TextEncoder;
exports.TextDecoder = TextDecoder;
exports.EncodingIndexes = EncodingIndexes;
exports.getEncoding = getEncoding;
export default exports;
const _TextEncoder2 = exports.TextEncoder,
      _TextDecoder2 = exports.TextDecoder,
      _EncodingIndexes = exports.EncodingIndexes,
      _getEncoding = exports.getEncoding;
export { _TextEncoder2 as TextEncoder, _TextDecoder2 as TextDecoder, _EncodingIndexes as EncodingIndexes, _getEncoding as getEncoding };